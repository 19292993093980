import React, { useState } from 'react'
import { Button, Col, Form, Row } from "react-bootstrap"
import { useNavigate } from 'react-router-dom';



const Instruction = () => {
    const navigate = useNavigate()
    const InstructionPdf = require("../../component/assets/Instruction.pdf");

    const [checked, setChecked] = useState<boolean>(false);

    return (
        <>
            <Row className="pt-4">
                <Col md={12}>
                    <object width="100%" height="700" data={InstructionPdf} type="application/pdf">   </object>
                </Col>
            </Row>
            <Row className="mt-3 ms-3">
                <Col>
                    <Form.Group>
                        <Form.Check
                            className='fw-bold fs-30'
                            type="checkbox"
                            label="Declaration, I have read, understand & agree to abide by all the above instructions."
                            checked={checked}
                            onChange={(e: any) => setChecked(e.target.checked)}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mt-4 ms-3">
                <Col>
                    <Button variant="success" disabled={!checked} onClick={() => navigate("/user-response")} className="mt-2 mb-2">Proceed</Button>
                </Col>
            </Row>
        </>
    )
}

export default Instruction