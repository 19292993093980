import React, { useState } from "react";

import { Button, Card, Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import { faKey, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../../../../component/service/auth.service";
import Auth from "../../../../component/Auth/auth";
import DatePicker from "react-date-picker";
import moment from "moment"


export default function LoginBox() {

  const navigate = useNavigate();

  const [regNo, setRegNo] = useState<any>();
  const [dob, setdob] = useState(new Date());

  const handleLogin = async (e: any) => {
    e.preventDefault();
    await AuthService.login(({
      dob: moment(dob).format("DD/MM/YYYY"),
      regno: regNo
    }))
      .then((res) => {
        if (res.status === 200) {
          Auth.authenticate();
          sessionStorage.setItem("authKey", res.data.token)
          navigate('/instruction')
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          console.error(err);
        } else if (err.response.status === 500) {
          alert(err.response.data)
        }
      })
  }





  return (
    <>
      <Card className="border-0 shadow mb-5"
        style={{ width: "30rem", borderRadius: "0.5rem", marginTop: "4rem" }}
      >
        <Card.Title className="text-center text-primary mb-2 mt-4">
          <h4>
            Candidate Login <FontAwesomeIcon icon={faUserTie} />
          </h4>
        </Card.Title>
        <Card.Body>
          <Card.Text>
            <div className="text-left">
              <Form.Group controlId="formBasicEmail" className="mt-3">
                <Form.Label className="fw-bold">
                  Registration Number
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Registration number"
                  name="email"
                  onChange={(e: any) => setRegNo(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mt-3" controlId="exampleForm.ControlInput2">
                <Form.Label className="fw-bold">
                  DOB
                </Form.Label><br />
                <DatePicker
                  onChange={(e: any) => setdob(e)}
                  className="form form-control"
                  format='dd-MM-yyyy'
                  value={dob}
                />
              </Form.Group>

              <div className="form-group mt-3">
                <Button
                  variant="primary"
                  className="w-50"
                  onClick={handleLogin}
                >
                  Login <FontAwesomeIcon icon={faKey} />
                </Button>
              </div>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}
