import makeRequest from "../api/makeRequest";
import { RequestMethods } from "../api/requestMethode";
import url from "../api/urls";

export class QuestionService {
    static async getAllQuestionIds(sectionId: any) {
        return await makeRequest(url.question.getAllQuestionIds + "/" + sectionId,  RequestMethods.GET);
    }

    static async getQuestionById(questionId: any) {
        return await makeRequest(url.question.getQuestionById + "/" + questionId, RequestMethods.GET)
    }
}