import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import Instruction from "../../View/Instruction/Instruction";
import Objection from "../../View/Objection/Objection";
import User from "../../View/User/User";
import IRouter from "../Interface/IRouter";
import UserQp from "../../View/UserQp/UserQp";




const mainRoutes: IRouter[] = [
    {
        path: "user-response",
        navbarShow: true,
        element: User,
        name: "My Responses",
        icon: faUser
    },
    {
        path: "objection",
        navbarShow: true,
        element: Objection,
        name: "Raise Objection",
        icon: faBullseye
    },
    // {
    //     path: "instruction",
    //     navbarShow: false,
    //     element: Instruction,
    //     name: "",
    //     icon: ""
    // },
    {
        path: "user-Qp",
        navbarShow: true,
        element: UserQp,
        name: "My Responses (2)",
        icon: faUser
    },
]


export default mainRoutes;