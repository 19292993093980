import { faGear, faArrowRightArrowLeft, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import IRouter from '../Interface/IRouter';
import { AuthService } from '../service/auth.service';
import { UserService } from '../service/user.service';
import './Topbar.css';

interface ITopBar {
  menuData: IRouter[],
}

export default function TopBar({ menuData }: ITopBar) {
  let navigate = useNavigate();
  let location = useLocation();

  const [user, setUser] = useState<any>();

  const getUserData = async () => {
    await AuthService.userData().then(res => {
      if (res.status === 200) {
        setUser(res.data.user[0]);
      }
    })
  }

  useEffect(() => {
    getUserData();
  }, [])

  const handleLogout = () => {
    sessionStorage.removeItem('authKey');
    navigate('/login');
  };

  const showAllowedMenu = menuData.filter((routes) => routes.navbarShow === true)

  return (
    <>
      <Navbar className="pl-3 d-flex justify-content-between bg-white">
        <div className='d-flex justify-content-start align-items-center'>
        <Navbar.Brand className="ms-2">NIXOBJ</Navbar.Brand>
        {
            showAllowedMenu.map((data, index) => {
              return (
                <div key={index}
                  className={"xrg-nav-link" + (data.path == location.pathname.split("/")[2] ? " xrg-nav-selected" : " ")}
                  onClick={() => navigate(data.path)}
                >
                  <div className='mx-2 '>
                    <span className="fw-bold text-primary" style={{ cursor: "pointer" }}>
                      {data.name}
                    </span>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="d-flex justify-content-evenly align-items-center me-2">
          
          <div>
            <div className="d-flex align-items-center me-2 reg-no">
              Reg No. <span className="fw-bold me-2">{user && user.regno}</span>
              <DropdownButton
                id="user-settings"
                variant="outline-secondary"
                size='sm'
                align="end"
                title={user && user.regno}>
                <Dropdown.Item className="font-weight-bold" onClick={handleLogout}> Logout </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        </div>

      </Navbar>
    </>
  );
}
