const url = {
    login: "/user/login",
    user: "/user/getUserData",
    getUserQp: "/user/qp",
    objection: {
        createObjection: "/objection/create",
        getAllObjection: "/objection/get",
        deleteObjection: "/objection/delete",
        editObjection: "/objection/edit",
        submitObject: "/objection/submit"
    },

    question: {
        getAllQuestionIds: "/question/getAllQuestionIds",
        getQuestionById: "/user/question"
    },
    getSections: "/user/sections"

};

export default url;
