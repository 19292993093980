import makeRequest from "../api/makeRequest";
import { RequestMethods } from "../api/requestMethode";
import makeUploadRequest from "../api/uploadRequest";
import url from "../api/urls";

export class UserService {
    static async createObjection(inputPayload: any) {
        return await makeUploadRequest(url.objection.createObjection, RequestMethods.POST, inputPayload)
    }


    static async getAllObjection(type: any) {
        return await makeRequest(url.objection.getAllObjection + "/" + type, RequestMethods.GET);
    }

    static async deleteObjection(objId: any) {
        return await makeRequest(url.objection.deleteObjection + "/" + objId, RequestMethods.DELETE);
    }


    static async editObjection(objId: any, payload: any) {
        return await makeRequest(url.objection.editObjection + "/" + objId, RequestMethods.PUT, payload);
    }

    static async submitObjection() {
        return await makeRequest(url.objection.submitObject, RequestMethods.POST)
    }

    static async getUserQp(sectionId: any) {
        return await makeRequest(url.getUserQp + "/"+ sectionId, RequestMethods.GET)
    }

    static async getSections(){
        return await makeRequest(url.getSections, RequestMethods.GET)
    }
}