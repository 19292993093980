import makeRequest from "../api/makeRequest";
import { RequestMethods } from "../api/requestMethode";
import url from "../api/urls";

export class AuthService {
    static async login(inputPayload: any) {
        return await makeRequest(url.login, RequestMethods.POST, inputPayload)
    }

    static async userData() {
        return await makeRequest(url.user, RequestMethods.GET);
    }
}