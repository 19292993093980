import { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Row } from "react-bootstrap"
import Select from "react-select"
import { toast } from "react-toastify";
import { UserService } from "../../component/service/user.service";

const UserQp = () => {
  const [userQpData, setUserQpData] = useState<any>();

  const [selectedSection, setSelectedSection] = useState<any>();

  const [sections, setSections] = useState<any>([]);

  const getUserQP = async () => {
    if (!selectedSection || !selectedSection.value) {
      return false;
    }
    await UserService.getUserQp(selectedSection.value).then((res: any) => {
      if (res.status === 200) {
        setUserQpData(res.data.userData)
        toast.success("Question Paper fetched Successfully")
      }
    }).catch((err: any) => {
      console.log("error")
      toast.error(err.response.data)
    })
  }

  const getSections = async () => {
    await UserService.getSections().then((res: any) => {
      setSections(res.data.sections.map((section: any) => {
        return {
          value: section.sectionId,
          label: section.sectionName
        }
      }))
    })
  }

  useEffect(() => {
    getSections()
  }, [])

  useEffect(() => {
    getUserQP()
  }, [selectedSection])

  return (
    <>
      <Container className="mt-4">
        <Alert variant='warning'>
          Correct Answer is Marked with <b className="text-success"> Green Background </b> Color.<br />
          Your Responses are Marked in <b className="text-primary"> check box </b>.<br />
        </Alert>
        <Card className="p-2 mb-3">
          <Card.Body>
            <Row>
              <Col md={4}>
                <Form.Group controlId="Current Address">
                  <Form.Label className="fw-bold">
                    Section
                  </Form.Label>
                  <Select
                    options={sections}
                    onChange={(e: any) => setSelectedSection(e)}
                    value={selectedSection}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {userQpData && userQpData.length > 0 &&
          userQpData.map((userResponses: any) => {
            return (
              <Card className='mb-4'>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4>Question</h4>
                      <span className='fs-10 fw-bold'>Question ID: {userResponses.question && userResponses.question.questionId} </span>
                    </div>
                  </div><hr className="text-muted" />
                  <div className="d-flex justify-content-top mt-3">
                    <span className="fw-bold fs-15">Q:- </span>
                    <div className="w-50 ms-3">
                      <img src={userResponses && userResponses.question && userResponses.question.image} width="100%" />
                    </div>
                  </div>
                  <div className="mt-4">
                    <span className="fw-bold fs-15">Options</span>
                    {userResponses && userResponses.question && userResponses.question.options.map((data: any) => {
                      const correct_answer = userResponses && userResponses.answer && userResponses.answer.answer.find((answerId: any) => answerId === data._id);

                      const user_response = userResponses && userResponses.response && userResponses.response.response.find((responseId: any) => responseId === data._id) || false;
                      return (
                        <div className="d-flex align-items-center">
                          <div style={{
                            width: "70%",
                            height: "5vh",
                            background: `${correct_answer ? "#93C29B" : ""}`,
                            marginBottom: "10px",
                            borderRadius: "5px",
                            padding: "10px"
                          }}>
                            <Form.Check
                              type="checkbox"
                              label={data.value}
                              className="fs-18 fw-bold"
                              checked={user_response}
                            />
                          </div>
                          {user_response && <span className=" ms-2 text-muted fs-20 font-italic">(Your Response)</span>}
                        </div>
                      )
                    })}

                  </div>
                </Card.Body>
              </Card>
            )
          })


        }
      </Container>
    </>
  )
}

export default UserQp