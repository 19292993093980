import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Tab, Tabs, Toast } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { UserService } from '../../component/service/user.service';
import DraftObjectionTable from '../../component/Table/DraftObjection.table';
import SubmittedObjectionTable from '../../component/Table/SubmittedObjection.table';
import Select from "react-select"
import ConfirmationModal from '../../component/Modal/Confirmation.modal';
import { QuestionService } from '../../component/service/question.service';

import { Formik } from "formik";

import * as yup from 'yup';


const Objection = () => {

  const [objectionData, setObjectionData] = useState<any>();
  const [data, setData] = useState<any>();
  const [submittedData, setSubmittedData] = useState<any>();
  const [supportingDoc, setSupportingDoc] = useState<any>();
  const [questionIds, setQuestionIds] = useState<any>();

  const [selectValue, setSelectValue] = useState<any>();
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [showCDraftonfirmationModal, setShowDraftConfirmationModal] = useState<boolean>(false);
  const [sectionName, setSectionName] = useState<any>();
  const [selectedSection, setSelectedSection] = useState<any>();
  const [selectedQuestionId, setSelectedQuestionId] = useState<any>();

  const handleSelectValue = (e: any, name: string) => {
    setSelectValue({ ...selectValue, [name]: e.value })
  }

  const getAllQuestionIds = async () => {
    if (!selectedSection || !selectedSection.value) {
      return false;
    }
    await QuestionService.getAllQuestionIds(selectedSection.value).then((res) => {
      if (res.status === 200) {
        setQuestionIds(res.data.questionIds)
      }
    })
  }



  const objectionTypeName = [
    {
      label: "Question is wrong",
      value: "Question is wrong"
    },
    {
      label: "Incorrect Option",
      value: "Incorrect Option"
    },
    {
      label: "Multiple Options are correct",
      value: "Multiple Options are correct"
    },
    {
      label: "None of the options is correct",
      value: "None of the options is correct"
    },
    {
      label: "Any Other",
      value: "Any Other"
    },
  ]

  const correctResponseName = [
    {
      label: "A",
      value: "a"
    },
    {
      label: "B",
      value: "b"
    },
    {
      label: "C",
      value: "c"
    },
    {
      label: "D",
      value: "d"
    }
  ]

  const handleUploadedDoc = (e: any) => {
    setSupportingDoc(e.target.files[0])

  }

  const handleObjectionData = (e: any) => {
    setObjectionData({ ...objectionData, [e.target.name]: e.target.value })
  }

  const getDraftObjection = async () => {
    await UserService.getAllObjection("draft").then((res) => {
      if (res.status === 200) {
        setData(res.data.objection);
      }
    })
  }

  const getSubmittedObjection = async () => {
    await UserService.getAllObjection("submitted").then((res) => {
      if (res.status === 200) {
        setSubmittedData(res.data.objection);
      }
    })
  }

  const handleObjectionSubmission = async (e: any) => {
    const formData = new FormData();
    formData.append("supportingDoc", supportingDoc);
    formData.append("questionId", selectedQuestionId && selectedQuestionId.value);
    formData.append("sectionName", selectedSection && selectedSection.label);
    formData.append("questionNumber", objectionData.questionNumber);
    formData.append("objectionType", selectValue && selectValue.objectionType);
    formData.append("suggestedCorrectResponse", selectValue && selectValue.suggestedCorrectResponse);
    formData.append("opinion", objectionData.opinion);
    formData.append("supportingDocDetails", objectionData.supportingDocDetails);

    await UserService.createObjection(formData).then(res => {
      if (res.status === 200) {
        setShowConfirmationModal(false)
        toast.success("Objection Added Successfully")
        getDraftObjection();
      }
    }).catch(err => {
      console.log("error")
      toast.error(err.response.data)
    })
  }

  const handleFinalObjectionSubmission = async () => {
    await UserService.submitObjection().then(res => {
      if (res.status === 200) {
        console.log(res.data);
      }
    }).catch(err => {
      console.log("error")
      toast.error(err.response.data)
    })
  }


  const getSections = async () => {
    await UserService.getSections().then((res: any) => {
      setSectionName(res.data.sections.map((section: any) => {
        return {
          value: section.sectionId,
          label: section.sectionName,

        }
      }))
    })
  }

  const schema = yup.object().shape({
    supportingDoc: yup.string().required(),
    questionId: yup.string().required(),
    sectionName: yup.string().required(),
    questionNumber: yup.string().required(),
    objectionType: yup.string().required(),
    suggestedCorrectResponse: yup.string().required(),
    opinion: yup.string().required(),
    supportingDocDetails: yup.string().required(),
  });

  function ObjectionForm() {
    return (
      <Formik
        validationSchema={schema}
        onSubmit={() => setShowDraftConfirmationModal(true)}
        initialValues={{
          supportingDoc: "",
          questionId: "",
          sectionName: "",
          questionNumber: "",
          objectionType: "",
          suggestedCorrectResponse: "",
          opinion: "",
          supportingDocDetails: ""
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          touched,
          isValid,
          errors,
          isSubmitting,
          handleSubmit
        }) => (
          <>
            <Row className="mt-2">
              <Col md={6}>
                <Form.Group controlId="Current Address">
                  <Form.Label className="fw-bold">
                    Section Name
                  </Form.Label>
                  {errors.sectionName && touched.sectionName && <div>{errors.sectionName}</div>}
                  <Select
                    options={sectionName}
                    onChange={(e: any) => setSelectedSection(e)}
                    value={selectedSection}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="Current Address">
                  <Form.Label className="fw-bold">
                    Question Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="questionNumber"
                    // onChange={handleObjectionData}
                    onChange={handleChange}
                    value={values.questionNumber}
                    onBlur={handleBlur}
                    isValid={touched.questionNumber && !errors.questionNumber}
                    required
                  />
                  {errors.questionNumber && touched.questionNumber && <div className="fw-bold text-warning">{errors.questionNumber}</div>}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={6}>
                <Form.Group controlId="Current Address">
                  <Form.Label className="fw-bold">
                    Question Id
                  </Form.Label>
                  <Select
                    options={questionIds && questionIds.map((qids: any) => {
                      return {
                        value: qids,
                        label: qids
                      }
                    })}
                    onChange={(e: any) => setSelectedQuestionId(e)}
                    value={selectedQuestionId}
                  />
                  {errors.questionId && touched.questionId && <div>{errors.questionId}</div>}
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group controlId="Current Address">
                  <Form.Label className="fw-bold">
                    Suggested Correct Response
                  </Form.Label>
                  <Select
                    options={correctResponseName}
                    onChange={(e: any) => handleSelectValue(e, "suggestedCorrectResponse")}
                  />
                  {errors.suggestedCorrectResponse && touched.suggestedCorrectResponse && <div>{errors.suggestedCorrectResponse}</div>}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={6}>
                <Form.Group controlId="Current Address">
                  <Form.Label className="fw-bold">
                    Opinion
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="opinion"
                    // onChange={handleObjectionData}
                    onChange={handleChange}
                    value={values.opinion}
                    onBlur={handleBlur}
                    isValid={touched.opinion && !errors.opinion}
                    required
                  />
                  {errors.opinion && touched.opinion && <div>{errors.opinion}</div>}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="Current Address">
                  <Form.Label className="fw-bold">
                    Objection Type
                  </Form.Label>
                  <Select
                    options={objectionTypeName}
                    onChange={(e: any) => handleSelectValue(e, "objectionType")}
                  />
                  {errors.objectionType && touched.objectionType && <div>{errors.objectionType}</div>}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={6}>
                <Form.Group controlId="Current Address">
                  <Form.Label className="fw-bold">
                    Supporting Document Details
                  </Form.Label>
                  <Form.Control
                    type="text"
                    // onChange={handleObjectionData}
                    onChange={handleChange}
                    value={values.supportingDocDetails}
                    onBlur={handleBlur}
                    isValid={touched.supportingDocDetails && !errors.supportingDocDetails}
                    name="supportingDocDetails"
                    required
                  />
                  {errors.supportingDocDetails && touched.supportingDocDetails && <div>{errors.supportingDocDetails}</div>}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="Current Address">
                  <Form.Label className="fw-bold">
                    Supporting Document
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="supportingDoc"
                    accept='.pdf, image/png, image/jpeg'
                    // onChange={handleUploadedDoc}
                    onChange={handleChange}
                    value={values.supportingDoc}
                    onBlur={handleBlur}
                    isValid={touched.supportingDoc && !errors.supportingDoc}
                  />
                  {errors.supportingDoc && touched.supportingDoc && <div>{errors.supportingDoc}</div>}
                </Form.Group>
              </Col>
            </Row>
          </>
        )}
      </Formik>
    )
  }


  useEffect(() => {
    getDraftObjection();
    getSections()
    getSubmittedObjection();
  }, [])

  useEffect(() => {
    getAllQuestionIds()
  }, [selectedSection])

  return (
    <>
      <Card className="mt-4">
        <Card.Body>
          {ObjectionForm()}
          <Button className="mt-3 flex-row justify-content-end " onClick={() => setShowConfirmationModal(true)}>Add Objection</Button>
        </Card.Body>
      </Card>
      <hr />
      <Card>
        <Card.Body>
          <h5>Draft Objection</h5>
          <DraftObjectionTable tableData={data} reload={getDraftObjection} data={data} setData={setData} />
          <Button className="mt-3" variant="warning" type="submit" >Submit</Button>
        </Card.Body>
      </Card>

      <Card className="mt-4">
        <Card.Body>
          <h5>Submitted Objection</h5>
          <SubmittedObjectionTable tableData={submittedData} />
        </Card.Body>
      </Card>

      <ConfirmationModal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        handleSubmission={handleObjectionSubmission}
        body={
          <>
            <span className="text-center fw-bold text-warning fs-15"> Are you sure you want to proceed </span>
          </>
        }
      />


      <ConfirmationModal
        show={showCDraftonfirmationModal}
        onHide={() => setShowDraftConfirmationModal(false)}
        handleSubmission={handleFinalObjectionSubmission}
        body={
          <>
            <span className="text-center fw-bold text-danger fs-15"> Once objections are submitted, objections can not be added or edited or deleted letter are you sure you want to proceed? </span>
          </>
        }
      />
    </>
  )
}

export default Objection