import { faCheck, faCross, faDownload, faEllipsisVertical, faPencil, faTrash, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Badge, Dropdown, Table } from 'react-bootstrap'
import CustomToggle from '../helpers/CustomToggle'
import EditObjectionModal from '../Modal/EditObjection.modal'
import { UserService } from '../service/user.service'

interface IObjectionTable {
    tableData: any,
    reload: any,
    data: any,
    setData: any
}

const DraftObjectionTable = ({ tableData, reload, data, setData }: IObjectionTable) => {

    const [objectionIndex, setObjectionIndex] = useState<number>(-1);


    const handleDeleteObjection = async (objId: any) => {
        await UserService.deleteObjection(objId).then((res) => {
            if (res.status === 200) {
                reload();
            }
        }).catch(err => {
            console.log(err.response.data)
        })
    }

    return (
        <>
            <Table striped hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Section Name</th>
                        <th>Question Number</th>
                        <th>Objection Type</th>
                        <th>QuestionId</th>
                        <th>Suggested Correct Response</th>
                        <th>Opinion</th>
                        <th>Supporting Documents</th>
                        <th>Supporting Documents Details</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData && tableData.length > 0 ? tableData.map((data: any, index: number) => {
                        return (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{data.sectionName}</td>
                                <td>{data.questionNumber}</td>
                                <td>{data.objectionType}</td>
                                <td>{data.questionId}</td>
                                <td>{data.suggestedCorrectResponse}</td>
                                <td>{data.opinion}</td>
                                <td>
                                    {data && data.supportingDoc ? <a href={data.supportingDoc} download><FontAwesomeIcon icon={faEye} className="ms-2 fs-20 fw-bold" /></a> : <FontAwesomeIcon icon={faCross} />}
                                </td>
                                <td>{data.supportingDocDetails}</td>
                                <td>
                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                            <FontAwesomeIcon icon={faEllipsisVertical} />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setObjectionIndex(index)}>
                                                <FontAwesomeIcon icon={faPencil} className="text-primary me-2" />
                                                <span className="fw-bold">
                                                    Edit
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleDeleteObjection(data._id)}>
                                                <FontAwesomeIcon icon={faTrash} className="text-danger me-2" />
                                                <span className="fw-bold">
                                                    Delete
                                                </span>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        )
                    }) : "No objection data found"}
                </tbody>
            </Table>
            <EditObjectionModal
                show={objectionIndex >= 0 ? true : false}
                handleClose={() => setObjectionIndex(-1)}
                data={tableData && tableData[objectionIndex]}
                reload={reload}
            />
        </>
    )
}

export default DraftObjectionTable