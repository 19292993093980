import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Navigate, useParams } from 'react-router-dom';
import Router from './Router';
import { ToastContainer } from 'react-toastify';

function App() {



  return (
    <>
      <div className="App">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
