import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { QuestionService } from '../../component/service/question.service'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftLong, faRightLong } from '@fortawesome/free-solid-svg-icons';
import Select from "react-select"
import { UserService } from '../../component/service/user.service';
import { toast } from 'react-toastify';

const User = () => {

    const [questionIds, setQuestionIds] = useState<any>();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0)
    const [sections, setSections] = useState<any>([]);
    const [selectedSection, setSelectedSection] = useState<any>();

    const [questionData, setQuestionData] = useState<any>();

    const getAllQuestionIds = async () => {
        if (!selectedSection || !selectedSection.value) {
            return false;
        }
        await QuestionService.getAllQuestionIds(selectedSection.value).then((res) => {
            if (res.status === 200) {
                setQuestionIds(res.data.questionIds)
                const questionId = res.data.questionIds && res.data.questionIds[currentQuestionIndex];
                if (questionId) {
                    getQuestionById(questionId)
                }
            }
        })
    }

    const getQuestionById = async (_id: any) => {
        await QuestionService.getQuestionById(_id).then(res => {
            if (res.status === 200) {
                setQuestionData(res.data);
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const getNextQuestion = () => {
        if (currentQuestionIndex >= questionIds.length - 1) {
            toast.error("This is the last question")
            return false
        }
        setCurrentQuestionIndex(currentQuestionIndex + 1)
    }

    const getPreviousQuestion = () => {
        if (currentQuestionIndex === 0) {
            toast.error("This is the first question")
            return false
        }
        setCurrentQuestionIndex(currentQuestionIndex - 1)
    }

    const getSections = async () => {
        await UserService.getSections().then((res: any) => {
            setSections(res.data.sections.map((section: any) => {
                return {
                    value: section.sectionId,
                    label: section.sectionName
                }
            }))
        })
    }

    useEffect(() => {
        getSections()
    }, [])


    useEffect(() => {
        getAllQuestionIds()
    }, [selectedSection])


    useEffect(() => {
        const questionId = questionIds && questionIds[currentQuestionIndex];
        if (questionId) {
            getQuestionById(questionId)
        }
    }, [currentQuestionIndex])

    useEffect(() => {
        getAllQuestionIds();
    }, [])

    return (
        <>
            <Container className="pt-4">

                <Alert variant='info'>
                    You can view one question at a time. <br />
                    Use Previus and Next button to navigate between questions.<br />
                    Correct Answer is Marked with <b className="text-success"> Green Background </b> Color.<br />
                    Your Responses are Marked in <b className="text-primary"> check box </b>.<br />

                </Alert>

                <Card className="p-2 mb-3">
                    <Card.Body>
                        <Row>
                            <Col md={4}>
                                <Form.Group controlId="Current Address">
                                    <Form.Label className="fw-bold">
                                        Section
                                    </Form.Label>
                                    <Select
                                        options={sections}
                                        onChange={(e: any) => setSelectedSection(e)}
                                        value={selectedSection}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className='mb-4'>
                    {questionData &&
                        <>
                            <Card.Body>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h4>Question</h4>
                                        <span className='fs-10 fw-bold'>Question ID: {questionIds && questionIds[currentQuestionIndex]} </span>
                                    </div>
                                    <div>
                                        <Button variant="success" size="sm" className="fw-bold me-2" onClick={getPreviousQuestion} ><FontAwesomeIcon icon={faLeftLong} /> Previous</Button>
                                        <Button variant="success" size="sm" className="fw-bold" onClick={getNextQuestion} >Next <FontAwesomeIcon icon={faRightLong} /></Button>
                                    </div>
                                </div><hr className="text-muted" />

                                <div className="d-flex justify-content-top mt-3">
                                    <span className="fw-bold fs-15">Q:- </span>
                                    <div className="w-50 ms-3">
                                        <img src={questionData && questionData.question && questionData.question.image} width="100%" />
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <span className="fw-bold fs-15">Options</span>
                                    {questionData && questionData.question && questionData.question.options.map((data: any) => {
                                        const correct_answer = questionData && questionData.answer && questionData.answer.answer.find((answerId: any) => answerId === data._id);

                                        const user_response = questionData && questionData.response && questionData.response.response.find((responseId: any) => responseId === data._id) || false;
                                        return (
                                            <div className="d-flex align-items-center">
                                                <div style={{
                                                    display: "flex",
                                                    width: "70%",
                                                    height: "5vh",
                                                    background: `${correct_answer ? "#93C29B" : ""}`,
                                                    marginBottom: "10px",
                                                    borderRadius: "5px",
                                                    padding: "10px"
                                                }}>
                                                    <Form.Check
                                                        type="checkbox"
                                                        label={data.value}
                                                        className="fs-18 fw-bold"
                                                        checked={user_response}
                                                    />
                                                </div>
                                                {user_response && <span className=" ms-2 text-muted fs-20 font-italic">Your Response</span>}
                                            </div>
                                        )
                                    })}

                                </div>

                            </Card.Body>
                        </>
                    }
                </Card>
            </Container>
        </>
    )
}

export default User