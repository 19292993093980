import { faEllipsisVertical, faEye, faPencil, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Badge, Dropdown, Table } from 'react-bootstrap'
import CustomToggle from '../helpers/CustomToggle'
import EditObjectionModal from '../Modal/EditObjection.modal'
import { UserService } from '../service/user.service'

interface IObjectionTable {
    tableData: any,
}

const SubmittedObjectionTable = ({ tableData }: IObjectionTable) => {

    return (
        <>
            <Table striped hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Sec. Name</th>
                        <th>Q.No</th>
                        <th>Obj Type</th>
                        <th>Suggested Correct Response</th>
                        <th>Opinion</th>
                        <th>Supp. Docs</th>
                        <th>Supp. Docs Details</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {tableData && tableData.length > 0 ? tableData.map((data: any, index: number) => {
                        return (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{data.sectionName}</td>
                                <td>{data.questionNumber}</td>
                                <td>{data.objectionType}</td>
                                <td>{data.suggestedCorrectResponse}</td>
                                <td>{data.opinion}</td>
                                <td>{data && data.supportingDoc && <a href={data.supportingDoc} download><FontAwesomeIcon icon={faEye} className="ms-2 fs-20 fw-bold" /></a>}</td>
                                <td>{data.supportingDocDetails}</td>
                                <td>
                                    {data.status === "submitted" ?
                                        <Badge bg="success" className="text-uppercase">
                                            Submitted
                                        </Badge> : <Badge className="text-uppercase" bg="success">
                                            {data.status}
                                        </Badge>}
                                </td>
                            </tr>
                        )
                    }) : "No objection data found"}
                </tbody>
            </Table>
        </>
    )
}

export default SubmittedObjectionTable