import React, { useEffect, useState } from 'react'
import { Button, Modal, Row, Col, Form } from 'react-bootstrap'
import { UserService } from '../service/user.service'


interface IEditObjectionModal {
    show: any,
    handleClose: any,
    data: any,
    reload: any
}

const EditObjectionModal = ({ show, handleClose, data, reload }: IEditObjectionModal) => {

    const [objectionData, setObjectionData] = useState<any>({})

    useEffect(() => {
        setObjectionData(data);
    }, [show])

    const handleObjectionData = (e: any) => {
        setObjectionData({ ...objectionData, [e.target.name]: e.target.value })
    }


    const handleEditSubmission = async () => {
        let payload = objectionData;

        await UserService.editObjection(objectionData._id, {
            questionId: objectionData.questionId,
            sectionName: objectionData.sectionName,
            questionNumber: objectionData.questionNumber,
            objectionType: objectionData.objectionType,
            suggestedCorrectResponse: objectionData.suggestedCorrectResponse,
            opinion: objectionData.opinion,
            supportingDoc: objectionData.supportingDoc,
            supportingDocDetails: objectionData.supportingDocDetails
        }).then((res) => {
            if (res.status === 200) {
                handleClose();
                reload();
            }
        })
    }

    return (
        <>
            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Objection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mt-2">
                        <Col md={6}>
                            <Form.Group controlId="Current Address">
                                <Form.Label className="fw-bold">
                                    Section Name
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="sectionName"
                                    defaultValue={objectionData?.sectionName}
                                    onChange={handleObjectionData}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="Current Address">
                                <Form.Label className="fw-bold">
                                    Question Number
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    defaultValue={objectionData?.questionNumber}
                                    name="questionNumber"
                                    onChange={handleObjectionData}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={6}>
                            <Form.Group controlId="Current Address">
                                <Form.Label className="fw-bold">
                                    Question Id
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="questionId"
                                    defaultValue={objectionData?.questionId}
                                    onChange={handleObjectionData}
                                    required
                                />
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group controlId="Current Address">
                                <Form.Label className="fw-bold">
                                    Suggested Correct Response
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    defaultValue={objectionData?.suggestedCorrectResponse}
                                    onChange={handleObjectionData}
                                    name="suggestedCorrectResponse"
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row> <Row className="mt-2">
                        <Col md={6}>
                            <Form.Group controlId="Current Address">
                                <Form.Label className="fw-bold">
                                    Opinion
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="opinion"
                                    defaultValue={objectionData?.opinion}
                                    onChange={handleObjectionData}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="Current Address">
                                <Form.Label className="fw-bold">
                                    Objection Type
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="objectionType"
                                    defaultValue={objectionData?.objectionType}
                                    onChange={handleObjectionData}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col md={6}>
                            <Form.Group controlId="Current Address">
                                <Form.Label className="fw-bold">
                                    Supporting Document Details
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    defaultValue={objectionData?.supportingDocDetails}
                                    onChange={handleObjectionData}
                                    name="supportingDocDetails"
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="Current Address">
                                <Form.Label className="fw-bold">
                                    Supporting Document
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="supportingDoc"
                                    defaultValue={objectionData?.supportingDoc}
                                    onChange={handleObjectionData}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleEditSubmission}>Edit</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditObjectionModal